import React from "react";
import { useForm } from "react-hook-form";
import "../styles.css";

import Input from "../../Form/Input";
import Select from "../../Form/Select";

import { textMap } from "./constants";
import { requireField, errorTypes, minNum, stepFloat, minNumFloat} from "../../../utils/formValidations";

function OrdersForm(props: any) {

  const { onSubmit, cutType } = props;
  const { register, handleSubmit, formState: { errors } } = useForm();

  function handleOnSubmit(formValues: any) {
    formValues.cut_type = textMap.get(cutType);
    onSubmit(formValues)
  }

  return (

    <form className="" onSubmit={handleSubmit(handleOnSubmit)}>
      <h5 className="fs-2 pt-4" style={{ color: "#6c757d" }}>Subiendo tu material a la mesa de trabajo</h5>

      <Select
        name={"material_type"}
        options={["Acero", "Aluminio", "Placa Aluminio", "Acero Carbon", "Acero Inoxidable", "Acrilico", "PVC espumado", "Madera", "Otro"]}
        label={"Material a cortar"}
        validation={{ required: requireField }}
        errorMessages={[{ type: errorTypes.required, message: "Material a cortar" }]}
        register={register}
        errors={errors}
      />
      <div className="text-left">
        <p> Al seleccionar "Otro" especifique en Comentarios</p>
      </div>
      <Input
        type={"float"}
        name={"thickness"}
        validation={{ required: requireField, min: minNum}}
        label={"Espesor"}
        placeholder="Medida desde 0.01"
        errorMessages={[{ type: errorTypes.required, message: "Ingrese el espesor (cal)" }, { type: errorTypes.min, message: "Calibre inválido" }]}
        register={register}
        errors={errors}
      />
      <Select
        name={"thickness_unit"}
        options={["Metros", "Pulgadas", "Pies", "Centímetros", "Milímetros", "Calibre"]}
        label={"Unidad de medida (Espesor)"}
        validation={{ required: requireField }}
        errorMessages={[{ type: errorTypes.required, message: 'Seleccione la unidad de medida' }]}
        register={register}
        errors={errors}
        disabled={""}
      />
      <div className="row">
        <div className="col-sm-6">
          <Input
            type={"float"}
            name={"width"}
            validation={{ required: requireField, min: minNum }}
            label={"Ancho"}
            placeholder="Ingrese la medida"
            errorMessages={[{ type: errorTypes.required, message: "Ingrese el ancho (inch)" }, { type: errorTypes.min, message: "Medida inválida" }]}
            register={register}
            errors={errors}
          />
        </div>
        <div className="col-sm-6">
          <Input
            type={"float"}
            name={"length"}
            validation={{ required: requireField, min: minNum }}
            label={"Largo"}
            placeholder="Ingrese la medida"
            errorMessages={[{ type: errorTypes.required, message: "Ingrese el largo (inch)" }, { type: errorTypes.min, message: "Medida inválida" }]}
            register={register}
            errors={errors} />
        </div>
      </div>
      <Select
        name={"width_length_unit"}
        options={["Metros", "Pulgadas", "Pies", "Centímetros", "Milímetros"]}
        label={"Unidad de medida (Ancho y Largo)"}
        validation={{ required: requireField }}
        errorMessages={[{ type: errorTypes.required, message: 'Seleccione la unidad de medida' }]}
        register={register}
        errors={errors}
        disabled={""}
      />
      <div className="row">
        <div className="col-sm-6">
          <Input
            type={"int"}
            name={"material_count"}
            validation={{ required: requireField, min: minNum }}
            label={"Cantidad de piezas de material a recibir"}
            placeholder="1"
            errorMessages={[{ type: errorTypes.required, message: "Cantidad de placas que nos enviarás" }, { type: errorTypes.min, message: "" }]}
            register={register}
            errors={errors} />
        </div>
        <div className="col-sm-6">
          <Input
            type={"int"}
            name={"cut_count"}
            validation={{ required: requireField, min: minNum }}
            label={"Cantidad de piezas a cortar"}
            placeholder="1"
            errorMessages={[{ type: errorTypes.required, message: "Cantidad de piezas a cortar" }, { type: errorTypes.min, message: "" }]}
            register={register}
            errors={errors} />
        </div>
      </div>
      <Input
        type={"textarea"}
        name={"notes"}
        validation={{ required: false }}
        label={"Comentarios"}
        placeholder="Comentarios"
        errorMessages={[{ type: errorTypes.required, message: "" }]}
        register={register}
        errors={errors}
        disabled={""}
      />
      <Input
        type={"file"}
        name={"file"}
        validation={{
          required: requireField,
          validate: function (value: any) {
            if (value[0].name.includes(".dxf") || value[0].name.includes(".dwg") || value[0].name.includes(".ai") || value[0].name.includes(".igs"))
              return true
            return false
          }
        }}
        label={"Archivo DWG, DXF, AI"}
        errorMessages={[{ type: errorTypes.required, message: "Adjunte el archivo" }, { type: errorTypes.validate, message: "Adjunte archivo con extensión DXF", }]}
        register={register}
        errors={errors} />


      <div className="text-center">
        <button type="submit" className="btn btn-primary btn-block btn">Enviar</button>
      </div>
    </form>

  );
};

export default OrdersForm;
